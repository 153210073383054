import React, {useState} from "react";
import { Link, useStaticQuery, graphql } from "gatsby";

const NOTICE = [
  {
    id: 3,
    type: 'Notice',
    label: '会員向けお知らせ'
  }
];

const NoticeListForMember = () => {
  const data = useData();
  const notices = [];

  NOTICE.forEach(v => {
    data[`allWp${v.type}`].nodes.forEach(node => {
      notices.push({ ...v, value: node });
    });
  })

  const yearList = Array.from(new Set([new Date().getFullYear().toString(), ...notices.map((notice, index) => {
    const { value } = notice;
    return value.date.slice(0, 4);
  })])).sort((a, b) => a > b ? -1 : 1);
  
  const yearMax = yearList[0];
  const [currentYear, setCurrentYear] = useState(yearMax);

  const yearListElement = yearList.map(year => {
    return <li><a onClick={() => setCurrentYear(year)} className={year === (currentYear == null ? yearMax : currentYear) ? 'current' : '' }>{year}年</a></li>
  }) 

  const htmlList = notices.map((page, index) => {
    const { id, label, value } = page;
    const year = value.date.slice(0, 4);
    const activeYear = currentYear == null ? yearMax : currentYear;
    if (year != activeYear) {
      return null;
    }
    
    return (
      <li key={index} className={`top_news_cat${id}`}>
        <span className="top_news_date">{value.date.slice(0, 10).replace(/-/g, '.')}</span>
        <span className={`label_base label0${id}`}>{label}</span>
        <span className="top_news_title">
          <Link to={value.uri}>
            {value.title}
          </Link>
        </span>
      </li>
    )
  })

  return (
    <section className="contents_block">
      <h2 className="header01">協会からのお知らせ（会員様向け）</h2>

      <div className="news_yearBtn">
        <ul>
          {yearListElement}
        </ul>
      </div>
      <div className="top_news_list newsPage inner">
        <ul className="mb30">
          <div >
            {htmlList.filter(v => v).length > 0 ? htmlList.filter(v => v) : `現在、今年の会員向けお知らせはありません`}
          </div>
        </ul>
      </div>
    </section>
  )
}

function useData() {
  return useStaticQuery(graphql`
  query {
    allWpNotice (limit: 15, sort: { fields: [date], order: DESC}, filter: {MemberOnly: {memberonly: {eq: true}}}) { 
      nodes {
        uri
        title
        date
        MemberOnly {
          memberonly
        }
      }
    }
  }
  `);
}
export default NoticeListForMember;

