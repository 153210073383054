import React from "react"
import { Link, navigate } from "gatsby"
import Seo from "../components/seo"
import { isBrowser, isLoggedIn, getJwt, getUser } from '../services/auth.js'
import NoticeListForMember from '../components/noticeListForMember.js'

class UserPage extends React.Component {
  state = {
    Jwt: ""
  }

  componentWillMount = () => {
    this.getJwt()
  }

  getJwt = async () => {
    if (isLoggedIn()) {
      if (await getJwt(getUser().MemberNo, ({ data: { body } }) => {
        this.setState({
          Jwt: body
        })
      })) { }
    }
  }

  render = () => {

    if (!isBrowser()) {
      return null;
    }

    if (!isLoggedIn()) {
      navigate('/login')
      return null;
    }
    const url = this.state.Jwt === "" ? "https://jrcea.libra.jpn.com/#/login" : process.env.SSO_LOGIN_URL + this.state.Jwt

    return (
      <>
        <Seo title="会員のページ" />
        <div id="pageTitle">
          <h1>
            会員のページ
          </h1>
        </div>
        <div className="breadClumb">
          <ul>
            <li><Link to="/">HOME</Link></li>
            <li>会員のページ</li>
          </ul>
        </div>

        <div id="main">
          <div className="contents">
            <NoticeListForMember />

            <section className="contents_block">

              <h2 className="header01">会員様向けサービス</h2>
              <div className="inner">
                <p className="mb30">会員は、協会誌を電子書籍で読みことが出来ます。<br />
                  鉄道施設技術に関する文献・資料・図書を検索し、閲覧することが出来ます。<br />
                  図書は事務所にお越し頂き、閲覧が出来ます。</p>

                <h3 className="header02">協会誌電子版の役割</h3>
                <p>協会誌電子版では、以下の資料文献を電子書籍として読むことができます。</p>
                <ul className="mb30">
                  <li>① 協会誌、直近約2年分を月号単位</li>
                  <li>② 業務資料シリーズ</li>
                  <li>③ 講演会概要集（近年分）</li>
                </ul>
                <h3 className="header02">施設協会ライブラリー協会誌/図書検索の役割</h3>
                <p>このライブラリーでは以下の収録データについて検索し、閲覧ができます。</p>
                <ul className="mb30">
                  <li>① 協会誌、保線ニュース、鉄道線路、鉄道土木は創刊からすべて(1953〜)本文全文が検索対象です</li>
                  <li>② 総合技術講演会(保線・土木)　本文全文検索可能</li>
                  <li>③ 鉄道施設技術発表会(保線・土木)　本文全文検索可能</li>
                  <li>④ 用地協議業務研究会　本文全文可能</li>
                  <li>⑤ オンラインで開催した講演会の収録ビデオ視聴</li>
                  <li>⑥ 過去の講演会記録 (保線講話会、改良講演会、保線講演会、停車場講演会、土木工事施工研究会、建造物検査技術講演会、軌道工事施工技術研究会) は、開催日、場所、タイトル、講演者で検索可能</li>
                  <li>⑦ 図書は、表紙あるいは目次の一部を表示（一部図書に限る）</li>
                  <li>⑧ 当協会発行の絶版図書、劣化した図書の全文PDF化（一部図書に限る）</li>
                </ul>
                <div className="aC mb80"><a href="https://opac.jp.net/Opac/search.htm?s=ZH7sRNxNfCs4qz33wJK3MYs_-xh" target="_blank" rel="noreferrer" className="btn_rounded">協会誌/図書検索はこちらから</a></div>
                <h3 className="header02">協会誌電子版の閲覧</h3>
                <p>日本鉄道施設協会誌をインターネット上でも閲覧（キーワード検索）いただけます。<br />
                  検索方法は、本棚に掲載してあります「総目次等の電子ブックご利用ガイド」をご覧ください。
                  <br />
                  協会誌電子版の本棚の構成は以下のようになっています。</p>
                <ul className="list_style2 mb50">
                  <li>1953(S28)-2002(H14)</li>
                  <li>2003(H15)-2016(H28)</li>
                  <li>2017(H29)〜単年で掲載</li>
                  <li>業務資料シリーズ</li>
                  <li>概要集（講演会等）</li>
                  <li>講演会等の記録ビデオ（視聴期間のみ）</li>
                </ul>
                <div className="aC mb80"><a href={url} rel="noreferrer" className="btn_rounded">協会誌電子版はこちら</a></div>

                <h3 className="header02">会員様向け教育</h3>
                <p>ただいま準備中です。</p>
              </div>
            </section>


            <section className="contents_block">
              <h2 className="header01">会員情報のお手続き</h2>
              <div className="inner mb1em">
                <h3 className="header02">変更届</h3>
                <p>住所等変更の際は変更申請フォームをご利用いただくか、変更届をダウンロードいただき、記入の上FAXにてお送りください。</p>
              </div>
              <ul className="list_style1 mb80">
                <li><a href="https://www.jrcea.or.jp/static/38a1a5b3889dd08ec57305b2f141ad25/nyukai_05.pdf" target="_blank" rel="noreferrer">変更届（PDF）</a></li>
                <li><Link to="/changeInfo">変更申請フォーム</Link></li>
              </ul>
              <div className="inner mb1em">
                <h3 className="header02">会員情報に関するお問い合わせ</h3>
                <p className="mb30">会員情報に関するお問い合わせは協会の会員係へお願いします。なお、特別会員はお近くの支部でも結構です。</p>
                <div className="aC">
                  <Link to="/access" className="btn_rounded">お問い合わせ先（アクセスマップ）</Link>
                </div>
              </div>
            </section>
          </div>
        </div>
      </>
    )
  }
}

export default UserPage;
